import React from 'react'; 
import { css } from '@emotion/react';
import {
  ExpCard,
  ExpCardLinkWrapper,
  ExpCardMedia,
  ExpCardSquare,
  ExpCardAddendum,
  ExpCardAddendumMain,
  ExpCardAddendumSub,
  ExpCardListItem,
  ExpCardSquareInner,
  ExpCardTitle,
} from "./expcard"
import getCompany from '../defaults/filters/getCompany';

export const PeopleCardInner = ({recordId, person}) => {
  
  return (
    <ExpCardLinkWrapper id={recordId} active="true">
      <ExpCard>
        <ExpCardSquare>
          {person.image && person.image.localFiles !== null ? (
            <ExpCardMedia img={person.image.localFiles}>
              <ExpCardSquareInner>
                <ExpCardAddendum>
                  <ExpCardTitle data={person.name} />
                  <ExpCardAddendumMain data={person.profession} />
                  <ExpCardAddendumSub
                    data={
                      // person.partner_company
                      //   ? person.partner_company[0].data.name
                      //   : person.startup_company
                      //   ? person.startup_company[0].data.startup
                      //   : person.custom_company
                      //   ? person.custom_company
                      //   : ""
                      getCompany(person, {
                        outputAll: false,
                        filterIncludes: false,
                      })?.name
                    }
                  />
                </ExpCardAddendum>
              </ExpCardSquareInner>
            </ExpCardMedia>
          ) : (
            <ExpCardSquareInner
              css={css`
                position: absolute;
              `}
            >
              <ExpCardAddendum>
                <ExpCardTitle data={person.name} />
                <ExpCardAddendumMain data={person.profession} />
                <ExpCardAddendumSub
                  data={
                    person.partner_company
                      ? person.partner_company[0].data.name
                      : ""
                  }
                />
              </ExpCardAddendum>
            </ExpCardSquareInner>
          )}
        </ExpCardSquare>
      </ExpCard>
    </ExpCardLinkWrapper>
  )
}

const PeopleCard = ( {data}, ...props ) => {
    let person = undefined;
    data.data === undefined ? (
      person = data
    ) : (
      person = data.data
    )
  return (
    <ExpCardListItem>
      <PeopleCardInner recordId={data.recordId} person={person} />
    </ExpCardListItem>
  )
}

export default PeopleCard;